import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './pvp.scss';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkePVPArena: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page pvp-nikke'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Basic Team-building and Meta Nikkes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_pvp4.jpg"
            alt="PVP"
          />
        </div>
        <div className="page-details">
          <h1>Basic Team-building and Meta Nikkes</h1>
          <h2>
            Everything you need to know about basic team-building and meta
            characters in NIKKE.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content with-italics">
        <SectionHeader title="Foreword" />
        <p>
          This guide has been created by <strong>awesome21snake</strong>. If you
          have any feedback about the guide, catch him on our Discord!
        </p>
        <SectionHeader title="Introduction" />
        <p>
          In SP Arena, no defense works.{' '}
          <strong>Essentially, defense is trash</strong>. CP gap is the best
          defense. Attackers will always have the advantage. Use the below
          template as a starting point for your defense team setups. It's not
          the best setup, but it'll work if the opponent isn't experienced (i.e.
          lacks general PvP knowledge).
        </p>
        <ul>
          <li>
            <strong>T1:</strong> 2-2.5RL Scarlet Jackal
          </li>
          <li>
            <strong>T2:</strong> Noah/Blanc Biscuit stall with SAnis
          </li>
          <li>
            <strong>T3:</strong> Do your best, Alice/Maiden/Power/Privaty are
            good main B3s.
          </li>
        </ul>
        <p>
          Read everything if you want a better understanding on PvP
          team-building, especially the Basic PvP building section.
        </p>
        <p>
          <strong>
            Point to Remember: There are no perfect teams for Arena.
          </strong>{' '}
          All you can do is try to win in defense by "knowledge checking your
          opponent", but if you have the units, investments and no CP Deficit:
          you SHOULD ALWAYS be winning in attack. However, PvP without key units
          is essentially just suffering. Refer to our Arena Mechanics guide for
          basic understanding of how PvP works.
        </p>
        <p>
          This guide was only made possible because of{' '}
          <strong>cleancascade</strong>. His insight towards PvP matchups along
          with his dedication towards testing is something I consider to be the
          best of anyone I have discussed PvP with. I thank him for being a huge
          help in the Nikke Community Discord and being an inspiration to
          complete a full PvP guide for everyone. A lot of what you see in this
          guide is by him.
        </p>
        <SectionHeader title="Not A Tier List" />
        <Alert variant="primary">
          <p>
            The big three are <strong>Jackal, Biscuit, and Scarlet</strong>.
            SAnis is a close fourth.
          </p>
        </Alert>
        <p>Order of importance: left to right within tiers.</p>
        <ul>
          <li>
            <strong>Warps the Meta:</strong> Most essential units in PvP. You
            should be trying to make the most of all these units on your setups.
            Missing any of them puts you at a disadvantage.
          </li>
          <li>
            <strong>Strong Picks:</strong> Good units who range from big
            play-makers to consistent filler. You can easily fit these units
            into your teams.{' '}
          </li>
          <li>
            <strong>Good Picks:</strong> Units that are usually used for burst
            gen, or can be useful as main units if you lack critical units.
          </li>
          <li>
            <strong>Situational/Replacements:</strong> useful every now and
            then, but generally get outclassed or are held back by noticeable
            flaws.
          </li>
          <li>
            <strong>Specialists:</strong> they have one or two serious use-case
            scenarios, which we will discuss later.
          </li>
        </ul>
        <Table striped bordered responsive className="table-nikke tier">
          <thead>
            <tr>
              <th>Tier</th>
              <th>Characters</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="red">
                <strong>Warp The Meta - B1</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="red">
                <strong>Warp The Meta - B2</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="red">
                <strong>Warp The Meta - B3</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  <NikkeCharacter
                    mode="icon"
                    slug="sparkling-summer-anis"
                    enablePopover
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="orange">
                <strong>Strong Picks - B1</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="noise" enablePopover />
                  <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="orange">
                <strong>Strong Picks - B2</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  <NikkeCharacter mode="icon" slug="noah" enablePopover />
                  <NikkeCharacter
                    mode="icon"
                    slug="miracle-fairy-anne"
                    enablePopover
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="orange">
                <strong>Strong Picks - B3</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  <NikkeCharacter mode="icon" slug="maiden" enablePopover />
                  <NikkeCharacter mode="icon" slug="2b" enablePopover />
                  <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  <NikkeCharacter mode="icon" slug="drake" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="yellow">
                <strong>Good Picks - B1</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="pascal" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="yellow">
                <strong>Good Picks - B2</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="nihilister" enablePopover />
                  <NikkeCharacter mode="icon" slug="viper" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="yellow">
                <strong>Good Picks - B3</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="privaty" enablePopover />
                  <NikkeCharacter mode="icon" slug="power" enablePopover />
                  <NikkeCharacter mode="icon" slug="harran" enablePopover />
                  <NikkeCharacter mode="icon" slug="a2" enablePopover />
                  <NikkeCharacter mode="icon" slug="laplace" enablePopover />
                  <NikkeCharacter mode="icon" slug="sugar" enablePopover />
                  <NikkeCharacter mode="icon" slug="vesti" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="cyan">
                <strong>Specialists</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="rosanna" enablePopover />
                  <NikkeCharacter mode="icon" slug="makima" enablePopover />
                  <NikkeCharacter mode="icon" slug="ludmilla" enablePopover />
                  <NikkeCharacter mode="icon" slug="nero" enablePopover />
                  <NikkeCharacter mode="icon" slug="emma" enablePopover />
                  <NikkeCharacter mode="icon" slug="folkwang" enablePopover />
                  <NikkeCharacter mode="icon" slug="tia" enablePopover />
                  <NikkeCharacter
                    mode="icon"
                    slug="winter-shopper-rupee"
                    enablePopover
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="gray">
                <strong>Situational - B1</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  <NikkeCharacter mode="icon" slug="neon" enablePopover />
                  <NikkeCharacter mode="icon" slug="mary" enablePopover />
                  <NikkeCharacter
                    mode="icon"
                    slug="bay-goddess-mary"
                    enablePopover
                  />
                  <NikkeCharacter mode="icon" slug="frima" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="gray">
                <strong>Situational - B2</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="poli" enablePopover />
                  <NikkeCharacter mode="icon" slug="diesel" enablePopover />
                  <NikkeCharacter mode="icon" slug="marciana" enablePopover />
                  <NikkeCharacter mode="icon" slug="mica" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td className="gray">
                <strong>Situational - B3</strong>
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                  <NikkeCharacter mode="icon" slug="quiry" enablePopover />
                  <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  <NikkeCharacter mode="icon" slug="brid" enablePopover />
                  <NikkeCharacter mode="icon" slug="snow-white" enablePopover />
                  <NikkeCharacter mode="icon" slug="helm" enablePopover />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          Alternatively, check out Keripo’s Tier List for PvP:{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1R6qzhnPx85kEoYidYUs-K0hJy61APMQGj5nRSgdvRUU/edit#gid=0"
            target="_blank"
            rel="noreferrer"
          >
            Keripo’s Tier List
          </a>
        </p>
        <SectionHeader title="Basic PvP Team-building" />
        <Alert variant="primary">
          <p>
            This section is for <strong>essential</strong> for everyone trying
            to understand PvP team building - PvP teams are very different from
            your usual campaign/raid teams.
          </p>
        </Alert>
        <p>
          Here are three major points to keep in mind when teambuilding, try to
          keep as many true as you can for your PvP teams. The third aim is very
          important. Make sure your team has at least 1 B1, 1 B2, and 1 B3.
          Positioning matters a lot.
        </p>
        <h5>Aim 1: Protect your P1 Position.</h5>
        <p>
          This is due to almost all weapon types focusing on the P1 position.
          Hence P1 is usually where your tankers go. This may not be important
          for setups in rare cases.{' '}
          <em>
            Note: If you are going up against defending SGs, you may need to
            protect P5 instead of P1.
          </em>
        </p>
        <p>You can try to Protect your P1 in the following ways:</p>
        <ul>
          <li>
            Use of Rapunzel or Anne:MF to revive P1 Nikke, [Additionally: Point
            1 of Aim 2]
          </li>
          <li>Use of Defender+Biscuit, with the Defender being in P1.</li>
          <li>
            Use of Taunt to deflect damage away from P1, for example Noah or
            Ludmilla in P5.
          </li>
          <li>Use of Jackal link to provide Nikke in P1 extra HP.</li>
          <li>Use of Makima to make P1 or P5 indomitable.</li>
          <li>Use of Emma or Diesel in P1 to counter Machine Guns.</li>
          <li>
            Use of target skipping to allow P1 Tankers (Noise and Noah) to
            survive (if they cannot). By using an extra unit of the same burst
            type to allow for full burst (for example: Noah P1 + Viper P2; or
            Noise P1 + Pepper P2, so that Viper or Pepper die instead of Noah,
            Noise or a DPS that might have died in P3/P4/P5).
          </li>
        </ul>
        <h5>Aim 2: Protect your Main Damage Dealer(s).</h5>
        <p>
          These units are called DPS commonly. This can be done in the following
          ways:
        </p>
        <ul>
          <li>
            If you are using your major DPS in P1, make sure the Nikke still has
            means of outputting damage. If it’s an AoE like Scarlet, you do not
            usually need to protect it in revive strats or Jackal strats.
            However if you need Damage over Time, e.g. like with SAnis or Alice,
            you will need extra protection for them, for example by using Blanc
            to make DPS indomitable, or by providing SAnis self heal through
            Biscuit.
          </li>
          <li>
            Use of healers such as Rapunzel/Folkwang to boost HP or give
            constant healing.
          </li>
          <li>Use of Max HP buffers like Noise to boost max HP.</li>
          <li>
            Use of Damage negaters like Noah to allow DPS to not take damage for
            some time.
          </li>
          <li>
            Use tanking strategies such as Defender(or Taunting
            Defender)+Biscuit
          </li>
          <li>
            Use of Privaty’s stun to give your DPS a window to do free damage
            in.
          </li>
          <li>
            Combination of two or more strategies listed to ensure DPS do not
            die.
          </li>
        </ul>
        <h5>Aim 3: Ensure that your teams have Good Burst Gen.</h5>
        <p>
          For defense, try to ensure that at least 2 teams out of 3 are 3RL or
          faster. If not 2, try to at least make 1 fast team. For Attack, try to
          match or outspeed your opponent’s teams. There are some cases where
          the slower team will win however.
        </p>
        <p>
          A 0.5RL faster burst will outspeed opponent B2s. A 1RL faster burst
          speed will outspeed opponent B1s.
        </p>
        <p>
          <strong>
            Note: There are slight delays between the Burst stage showing up and
            the burst getting triggered. This can affect the point given in the
            previous statement.
          </strong>
        </p>
        <SectionHeader title="Meta Nikkes in PvP" />
        <p>
          This section will discuss and show (with videos where applicable) how
          Meta Nikkes in PvP work, and how you may use them. Nikkes like Jackal
          or Scarlet have a versatile skill set, so I cannot show everything
          that they can do. However I will try my best to encompass everything
          they can do in the most basic terms I can.
        </p>
        <p>
          <strong>
            I thank the following users from Nikke Community Discord and
            Prydwen's Official Discord for sharing their videos with us:
            shuryo_, zixk98, lazyremy, Kyd#7256, makurisu, mandingo1822, Keripo
            and of course, cleancascade!
          </strong>
        </p>
        <SectionHeader title="Warp The Meta" />
        <h5>Jackal</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="jackal" enablePopover />
        </div>
        <p>
          <strong>Important skills to know about:</strong>
        </p>
        <ul>
          <li>
            Skill 1: For every 10 shots that Jackal or Jackal-linked Nikkes get
            hit with, Jackal applies a debuff on opponents as a counter attack
            which gives extra Burst Gen to her team, equal to Jackal’s base
            burst gen value (3.55). Hence, Jackal has the power to significantly
            boost Burst Gen of your team.{' '}
          </li>
          <li>
            Skill 2: Links 2 Nikkes and shares damage taken, essentially giving
            all 3 units 3 HP bars. Synergises with Skill 1. All linked Nikkes
            will count "hits" on any of the 3 linked Nikkes as hits made against
            themselves. Hence Nikkes like Jackal or Scarlet/Emma have a chance
            to get extra burst gen or damage/heal if hits are made on any one of
            the linked Nikkes.
          </li>
        </ul>
        <p>
          Important: Due to this skill, you want Jackal linked nikkes to be on
          P1/P2 (or P5 if against defending shotguns)
        </p>
        <p>
          <strong className="green">Strength:</strong> Best Burst Gen in the
          game. Great synergy with Scarlet (and Emma). Can increase the Burst
          Gen Speed of a team (e.g. from 2.5RL - &gt; 2RL) through her S1. Burst
          buffs 2B burst. Her S1 can restrict Weapon Type Usage against her. The
          Queen of PvP in Nikke. High flexibility, fits in virtually every team.
        </p>
        <p>
          <strong className="red">Weaknesses:</strong> Rosanna can remove Jackal
          link. 2B can nuke Jackal-link to do high amounts of damage if the
          Highest HP unit is linked. If Alice focuses on Jackal linked Nikke,
          she can kill off the Jackal linked nikkes very quickly due to her high
          single target damage.
        </p>
        <h5>Biscuit</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
        </div>
        <p>
          <strong>Important skills to know about:</strong>
        </p>
        <ul>
          <li>
            Skill 2: Makes defenders invincible for 5 seconds when they reach
            50% HP. This activates twice.
          </li>
          <li>
            Burst: Gives ATK buff and gives "Heals x% of damage dealt" to
            Support Type Nikkes e.g SAnis
          </li>
        </ul>
        <p>
          <strong className="green">Strengths:</strong> Good Burst Gen. Counters
          Alice. Buffs SAnis with Burst. Can be paired with defenders to soak up
          damage.
        </p>
        <p>
          <strong className="red">Weaknesses:</strong> Only works with SAnis and
          Defenders, limiting flexibility.
        </p>
        <h5>Scarlet</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
        </div>
        <p>
          <strong>Important skills to know about:</strong>
        </p>
        <ul>
          <li>
            Skill 1: Gives herself an insane ATK buff every 10 bullets shot
            which can stack to be &gt; 100% in total
          </li>
          <li>
            Skill 2: Whenever Scarlet is hit, there’s a 30% chance of doing 138%
            of ATK as damage to the opponent who attacked. This effect is more
            clear when Scarlet is paired with Blanc or Jackal. This effect is
            magnified a lot when facing SGs or any Nikke with a high fire rate.
            However since the chance is 30%, RLs can also get affected by this
            with enough bad luck.
          </li>
          <li>Burst: A very high damage AoE nuke.</li>
        </ul>
        <p>
          <strong className="green">Strengths:</strong> High Damage Nuke. Can do
          significant damage outside of Burst. Highly flexible, can fit in any
          team. Her S2 can restrict Weapon Type Usage against her. The God PvP
          DPS.
        </p>
        <p>
          <strong className="red">Weaknesses:</strong> Loses HP constantly. AoE
          can be blocked by Noah or survived by the use of Noise. Scarlet S2 may
          need high investments at higher synchro levels.
        </p>
        <h5>Summer Anis</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="sparkling-summer-anis"
            enablePopover
          />
        </div>
        <p>
          <strong>Important skills to know about:</strong>
        </p>
        <ul>
          <li>
            Skill 1: Several offensive buffs given to Electric Code Nikkes at
            the start of Full Burst.
          </li>
          <li>
            Skill 2: Main source of DPS; sends 2 missiles that do a lot of DPS
            to 2 enemies with highest ATK, but after every last bullet shot.
          </li>
          <li>
            Burst: Reduces ammo. Synergises with S2, allowing Anis to trigger
            her S2 more times against opponents. Hence her major DPS output is
            during her burst.
          </li>
        </ul>
        <p>
          <strong className="green">Strengths:</strong> Ultimate counter to
          stall comps. If paired with Rosanna, Biscuit and Noise, she possesses
          the power to beat even her fiercest rival: A Scarlet-Jackal comp.
        </p>
        <p>
          <strong className="red">Weaknesses:</strong> Feeds into Scarlet S2 and
          Jackal S1. Her S2 can be taunted and she will most likely lose if both
          of her missiles get negated. Hence, if damage during burst is
          nullified, she will probably lose the fight.
        </p>
        <SectionHeader title="Strong Picks" />
        <h5>Noise</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="noise" enablePopover />
        </div>
        <p>
          <strong>Important skills to know about:</strong>
        </p>
        <ul>
          <li>
            Skill 2: Self Heals and Taunts after a fully charged shot hits
            opponents. Taunt has no cooldown but Self Heal essentially has a
            once every 2 second cooldown timer. She taunts all opponents she
            manages to hit, which means she will usually taunt 2, and possibly 3
            opponents, depending on how many enemies she hits.
          </li>
          <li>
            Burst: Team-wide Max HP buff that allows the team to survive AoE.
            Also heals the team for the next 10 seconds.
          </li>
        </ul>
        <p>
          <strong className="green">Strengths:</strong> Good Burst Gen since RL
          unit. Burst allows the team to survive AoE attacks. Self Heal allows
          her to act as a tanker. One of the strongest B1 units.
        </p>
        <p>
          <strong className="red">Weaknesses:</strong> Burst may need to be
          leveled against High Damage AoE Bursts. Self Heal has a technical
          cooldown. Need to reach Burst to block AoE attacks.
        </p>
        <p>
          <strong>Additional Notes:</strong> You can do some quirks with Noise
          and SAnis together, e.g Noise can be made higher ATK than SAnis so
          that opponent SAnis targets her over your SAnis.
        </p>
        <p>Examples:</p>
        <Row xs={1} lg={2}>
          <Col>
            <h5>
              Noise (higher ATK than SAnis) tanking for SAnis so that our SAnis
              has more time to kill opponent
            </h5>
            <YoutubeEmbed embedId="QnmxeVqWQ8A" />
          </Col>
          <Col>
            <h5>
              Noise tanks AoE and then allows Biscuit to Burst so that SAnis
              becomes pseudo invincible
            </h5>
            <YoutubeEmbed embedId="8PnshOaPAbg" />
          </Col>
        </Row>
        <h5>Noah</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="noah" enablePopover />
        </div>
        <p>
          <strong>Important skills to know about:</strong>
        </p>
        <ul>
          <li>
            Skill 2: Noah taunts on fully charged shots just like Noise does.{' '}
          </li>
          <li>
            Burst: Completely nullifies any damage dealt to your team for 3
            seconds at the start of her burst. Additionally, she has a constant
            10 second long taunt which taunts all enemies (except Alice since
            she just ignores it sometimes)(1 of the 3 long taunt durations).
          </li>
        </ul>
        <p>
          <strong className="green">Strengths:</strong> Major use is in negating
          AoE damage completely. Her taunt, if paired with biscuit, straight up
          leads to a long duration where Noah will make your team immune from
          ANY damage being done to your team for 10-13 seconds.
        </p>
        <p>
          <strong className="red">Weaknesses:</strong> Needs to reach burst to
          negate AoE damage. Since she is B2, her burst can be outsped by a
          0.5RL difference usually, since a 0.5RL faster Burst Speed AoE attack
          can outspeed a B2 Burst.
        </p>
        <h5>Rapunzel / xAnne</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
          <NikkeCharacter mode="icon" slug="miracle-fairy-anne" enablePopover />
        </div>
        <p>
          <strong className="green">Rapunzel Strengths:</strong> More of a
          defensive reviver and a strong B1 Unit choice. Provides constant heals
          and has teamwide heal on full burst. Additionally, revives the highest
          ATK unit at full burst. Rapunzel has a special use sometimes in which
          the team is set up such that Rapunzel is Burst after the 2nd round of
          Burst. The classic rookie god comp is set up this way (Noise Noah
          Scarlet Rapunzel Jackal).
        </p>
        <p>
          <strong className="green">xAnne Strengths:</strong> More of an
          offensive reviver and is a B2 unit. Her major buffs are strictly for
          Attacker type Nikkes only. Her burst significantly buffs the ATK and
          heals Attacker units only. After that she revives one Attacker Unit
          randomly. She can also lower enemy team’s healing with her skill 2
        </p>
        <p>
          Both of these units can be used strategically to gain advantage. There
          is no real weakness of using them, except that xAnne only provides her
          buffs to Attacker type units.
        </p>
        <h5>Blanc</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="blanc" enablePopover />
        </div>
        <p>
          Blanc is special. Blanc provides heals, attacking buffs, and a very
          clutch indomitability. But everything is locked behind her burst. The
          main aim is to reach her burst. This can allow your Main DPS to go
          wild, even if all of your other team is dead. Scarlet, SAnis and Alice
          are key users of this indomitability. She can be paired with Biscuit
          for survival.
        </p>
        <p>
          <strong className="red">Weaknesses:</strong> Low burst gen. ARs may
          feed into Jackal and Scarlet.
        </p>
        <p>Examples:</p>
        <Row xs={1} lg={3}>
          <Col>
            <h5>Blanc with Scarlet</h5>
            <YoutubeEmbed embedId="hpWYczFhqzw" />
          </Col>
          <Col>
            <h5>Blanc with Alice</h5>
            <YoutubeEmbed embedId="dyujOtcgxYw" />
          </Col>
          <Col>
            <h5>Blanc with SAnis</h5>
            <YoutubeEmbed embedId="x1HjdcxDqV8" />
          </Col>
        </Row>
        <h5>Centi / Anis</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="centi" enablePopover />
          <NikkeCharacter mode="icon" slug="anis" enablePopover />
        </div>
        <p>
          These Nikkes are used for the very high burst generation that they can
          provide to teams.
        </p>
        <h5>Alice</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="alice" enablePopover />
        </div>
        <p>
          <strong className="green">Strengths:</strong> Straight up a single
          target damage dealer. Alice’s Burst allows her to do insane damage.
          While attacking, Alice is still as wild as she used to be, especially
          because of her wild targeting (explained in Arena Mechanics Guide).
          Just don’t fight against Biscuit teams preferably.
        </p>
        <p>
          <strong className="red">Weaknesses:</strong> Biscuit just allows all
          of her damage to straight up get soaked up by a defender in P1 or in
          general a taunting defender. May require buffers like Blanc or Maxwell
          to beat some comps.
        </p>
        <p>Examples:</p>
        <Row xs={1} lg={2}>
          <Col>
            <h5>Alice murdering God Comps</h5>
            <YoutubeEmbed embedId="hpWYczFhqzw" />
          </Col>
          <Col>
            <h5>Alice being countered by Biscuit+Defender</h5>
            <YoutubeEmbed embedId="KibDTOeECMI" />
          </Col>
        </Row>
        <h5>Maiden / Harran / 2B</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="maiden" enablePopover />
          <NikkeCharacter mode="icon" slug="harran" enablePopover />
          <NikkeCharacter mode="icon" slug="2b" enablePopover />
        </div>
        <p>
          All 3 exist as weaker Scarlet replacements, but have serious
          drawbacks.
        </p>
        <p>
          <strong className="green">Maiden Strengths:</strong> Decent AoE on
          burst if she manages to proc Revenge (her skill 1). High Burst Gen.
          Can work as a sub B3, especially in the Flex spot for SAnis teams
          since her code is Electric.
        </p>
        <p>
          <strong className="red">Maiden Weaknesses:</strong> Feeds into Jackal
          and Scarlet. Damage outside of burst can be hard countered with Makima
          in P5. Her AoE is weaker than Scarlet’s, and is hence negated
          extremely well by Noise.
        </p>
        <p>
          <strong className="green">Harran Strengths:</strong> Strong AoE burst.
        </p>
        <p>
          <strong className="red">Harran Weaknesses:</strong> No damage outside
          of Burst. Her High burst gen is based on RNG (25% chance to activate
          skill 1). Her AoE is weaker than Scarlet’s.
        </p>
        <p>
          <strong className="green">2B Strengths:</strong> Very strong AoE burst
          which has an extra nuke for Highest HP opponent. Uses unusual buffers
          to buff her damage (Noise and Jackal are ideal). Can counter Jackal
          link very well if the highest HP unit is linked to Jackal.
        </p>
        <p>
          <strong className="red">2B Weaknesses:</strong> 2B can be absolutely
          destroyed by Rosanna, who removes 2B’s self ATK buff that she gets
          from her skill 2, and also by Biscuit+Ludmilla/Nero, who can cause her
          AoE to do basically 0 Damage. This is due the nature of how
          Distributed Damage works. 2B’s burst is distributed damage, which is
          calculated based on the designated enemy’s damage taken, and it is
          then evenly distributed according to the number of enemies on the
          field. Since the designated enemy in this case was Ludmilla, and she
          was immune to damage due to Biscuit’s Skill 2, 2B does 0 damage. Huge
          thanks to Keripo for finding both of these counters to 2B and
          publicizing them.
        </p>
        <p>Examples:</p>
        <Row xs={1} lg={2}>
          <Col>
            <h5>2B burst being blocked by Biscuit+Ludmilla</h5>
            <YoutubeEmbed embedId="fLlgyI2UxsY" />
          </Col>
          <Col>
            <h5>2B damage plummeting after using Rosanna</h5>
            <YoutubeEmbed embedId="D95eCLr-zi8" />
          </Col>
        </Row>
        <h5>Pepper / Drake / Noir</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="pepper" enablePopover />
          <NikkeCharacter mode="icon" slug="drake" enablePopover />
          <NikkeCharacter mode="icon" slug="noir" enablePopover />
        </div>
        <p>
          These Nikkes exist as Burst Gen Batteries while also doing damage,
          however they each provide one or two small additional perks. Noir and
          Drake provide teamwide ATK buffs. Noir provides it constantly as long
          as she has enough HP, while Drake provides it during Full Burst. Noir
          also has ammo buffs that she provides. Pepper is commonly known as a
          healer in PvE. However in PvP Arena, she is straight up used as a
          “Harmacist” damage dealer. She will nuke the highest ATK opponent when
          she Bursts (a great pair to Power’s nuke, which does the same), and
          being a shotgun, she will also output decent DPS.
        </p>
        <p>
          <strong className="red">Major weaknesses:</strong> Feed into Jackal
          and can get blocked by Makima from doing damage. Pepper’s nuke can be
          taunted away.
        </p>
        <h5>Privaty</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="privaty" enablePopover />
        </div>
        <p>
          Usually used to bully slow comps, Privaty is used in PvP for her stun
          mechanic which literally stops the enemy from doing anything and
          everything for 3 seconds. This allows DPS in your team to do damage
          freely for the said time duration. Can be used in very specific
          situations to kill off opponents who may have left their Main DPS
          unprotected. Can also be used to ‘waste’ time of opponent Nikkes who
          have major DPS output during Burst (e.g. Alice), or can allow Ally
          shotguns to target skip a Makima who is keeping them stuck in P1.
        </p>
        <p>
          <strong className="red">Major weaknesses:</strong> Low Burst Gen,
          Depends on other Nikkes to output DPS. Usually very simple to counter
          due to low survivability of Privaty based comps.
        </p>
        <Row xs={1} lg={2}>
          <Col>
            <h5>Privaty countering Makima</h5>
            <YoutubeEmbed embedId="_PWewtldK6U" />
          </Col>
        </Row>
        <h5>Power / A2 / Laplace</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="power" enablePopover />
          <NikkeCharacter mode="icon" slug="a2" enablePopover />
          <NikkeCharacter mode="icon" slug="laplace" enablePopover />
        </div>
        <p>
          All 3 exist as Good Burst Gen batteries. Power and A2 have decent
          quirks but Laplace is solely for Burst Gen as her Burst seems to not
          do a lot in PvP.
        </p>
        <p>
          <strong className="green">Power Strengths:</strong> Burst is a
          powerful single target nuke which nukes the highest ATK enemy.
        </p>
        <p>
          <strong className="red">Power Weaknesses:</strong> Hard countered by
          slower revive teams, who can just revive their Main DPS who died from
          the nuke. In general, very easily countered as her nuke can be taunted
          or blocked as well.
        </p>
        <p>
          <strong className="green">A2 Strengths:</strong> High Burst Gen for
          some Burst Speeds. Hits 3 Nikkes normally. Can hit 5 Nikkes during
          burst, hence can be used as a main DPS option.
        </p>
        <p>
          <strong className="red">A2 Weaknesses:</strong> Requires babysitting
          by healers since she kills herself. Slower than other RL to shoot,
          lowers burst gen in some Burst Gen speeds. Her damage output is slow
          and can be countered with proper use of Noise or other healers.
        </p>
        <SectionHeader title="Specialists" />
        <h5>Rosanna</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="rosanna" enablePopover />
        </div>
        <p>
          Rosanna has a special Skill 1, which was purely designed for PvP. She
          dispels buffs that opponents have, and hence is used for two major
          fights:
        </p>
        <ul>
          <li>
            To remove Jackal link from Nikkes, so that our team does not feed
            into the opponent team. This can save the likes of SAnis from
            feeding into Scarlet and dying, and will also ensure that the enemy
            team is not getting extra burst gen. Just make sure she isn’t linked
            to Jackal while facing a Scarlet, or she will kill everyone in your
            team due to activating Scarlet’s S2 too many times.
          </li>
          <li>
            To completely shut-down 2B by dispeling her Skill 2’s ATK buff. She
            quite literally just dispel’s 2B’s ATK buff, making 2B go from
            Riches to Rags. (explained in 2B section)
          </li>
        </ul>
        <Row xs={1} lg={2}>
          <Col>
            <h5>Rosanna killing scar jack link</h5>
            <YoutubeEmbed embedId="bcTilGR3FXk" />
          </Col>
        </Row>
        <h5>Makima</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="makima" enablePopover />
        </div>
        <p>
          Makima is the ULTIMATE counter to Defending SGs. Due to her
          indomitability, which lasts for 8 seconds, she gives more than enough
          time for our team to burst. Just place her in P5 when attacking and
          see the magic happen as defender SGs struggle to shoot at anyone but
          Makima.
        </p>
        <p>Examples:</p>
        <Row xs={1} lg={2}>
          <Col>
            <h5>Makima blocking Alice in attack</h5>
            <YoutubeEmbed embedId="UxRXw3jMdM4" />
          </Col>
          <Col>
            <h5>Makima blocking SGs in attack</h5>
            <YoutubeEmbed embedId="Hxsn3yOY3n4" />
          </Col>
        </Row>
        <h5>Emma / Diesel</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="emma" enablePopover />
          <NikkeCharacter mode="icon" slug="diesel" enablePopover />
        </div>
        <p>
          What Makima is to SGs, is what Emma is to MGs. Emma’s Skill 1 is a
          unique skill which has a 5% chance of healing 10% HP of all allies
          when attacked. Due to the insanely high fire rate of MGs (and
          sometimes even SMGs and ARs if there is a Jackal-Emma link) they allow
          Emma to proc (activate) her skill 1 to heal allies multiple times per
          second. This keeps the whole team extremely healthy and you simply
          become immune from taking damage against MGs. This effect is
          exaggerated if there is an Emma-Jackal link, a team which was seen a
          lot in the old meta. Diesel needs to burst to get the same effect, in
          which her skill 1 allows her to self heal when she takes damage.
        </p>
        <p>Example video:</p>
        <Row xs={1} lg={2}>
          <Col>
            <YoutubeEmbed embedId="9bmuOy28BHE" />
          </Col>
        </Row>
        <h5>Folkwang</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="folkwang" enablePopover />
        </div>
        <p>
          Folkwang is a special Nikke which gives self heal to your DPS for 10
          seconds. This allows for some comps where your B3 is essentially
          invincible (but really not). She can also be paired with Biscuit, who
          provides invincibility to Folkwang, allowing her to tank in P1, in
          addition to supporting Main DPS.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="Ra72l2DxOOc" />
          </Col>
        </Row>
        <h5>Nero</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="nero" enablePopover />
        </div>
        <p>
          Nero is a taunter like Noah except that she does not block AoE or
          provide invincibility. She is one of the 3 long taunts. She is more or
          less required to be paired with Biscuit as using her as a solo B2 can
          be dangerous, and this will not allow her to soak up damage properly.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="2OaCNHqqWv4" />
          </Col>
        </Row>
        <h5>Ludmilla</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="ludmilla" enablePopover />
        </div>
        <p>
          Ludmilla is the third long taunt, but she is a B1. However, her taunt
          is at full burst as it is a passive skill. Hence, she can also act as
          an off burst taunter. She is usually used to allow Biscuit to Burst to
          support SAnis. Both Nero and Ludmilla provide damage reduction to
          themselves, however Nero provides it to her healer as well. Do not
          forget that she can be paired with Biscuit to completely shut down 2B!
        </p>
        <p>Examples:</p>
        <Row xs={1} lg={2}>
          <Col>
            <h5>Ludmilla as an off Burst taunter</h5>
            <YoutubeEmbed embedId="R1BbSoCCfq8" />
          </Col>
          <Col>
            <h5>Ludmilla allowing Biscuit to Burst to make SAnis invincible</h5>
            <YoutubeEmbed embedId="bnZ1rP7e9a8" />
          </Col>
        </Row>
        <h5>Tia / xRupee</h5>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="tia" enablePopover />
          <NikkeCharacter
            mode="icon"
            slug="winter-shopper-rupee"
            enablePopover
          />
        </div>
        <p>
          Tia and xRupee are replacements for when you do not have Ludmilla.
          They both are not as viable as Ludmilla, and they both need another B1
          to be used. An example team comp would be Tia/xRupee Biscuit SAnis
          Anis Noise/Pepper. Tia provides Attack Damage buff while xRupee
          provides Reload Speed buff. Both are being used to block off damage at
          P1 with Biscuit, and also allow Biscuit to burst to buff SAnis, but
          cannot defend against AoE. Tia’s taunt is at 5RL (i.e when her 5th
          shot lands) while xRupee has taunt on Burst. Both of them may be
          possibly used to block 2B just like Ludmilla.
        </p>
        <SectionHeader title="Next step" />
        <p>
          We will now move on to Introducing Attack and Defence Teams in the
          Current Meta as the final article for the PvP learning experience.
          Remember that the 3 aims of Basic Team-building are very very
          important.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Meta Teams and Archetypes"
            link="/nikke/guides/pvp-sp-arena-teams"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_pvp5.jpg"
                alt="Meta Teams and Archetypes"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkePVPArena;

export const Head: React.FC = () => (
  <Seo
    title="Basic Team-building and Meta Nikkes | NIKKE | Prydwen Institute"
    description="Everything you need to know about basic team-building and meta characters in NIKKE."
    game="nikke"
  />
);
